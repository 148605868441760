import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Check} from '@mui/icons-material';
import {NavLink, NavItem, Nav, UncontrolledTooltip, Modal} from "reactstrap";
import Select, { components } from "react-select";
import {useLocation} from "react-router-dom";
import Joyride from 'react-joyride';

import i18n from "../../i18n";
import {LoaderSpinner} from "../../components/Common/Loader";
import LeafIcon from "../../components/Common/Leaf/leafIcon";

import {
  createCompanyGreenStep,
  updateCompanyGreenStep,
  deleteCompanyGreenStep,
  editComment,
  getGreenStepsNew,
  getGreenStepTips
} from "../../store/actions/greenStep";
import {createUpdateActionPlan, deleteActionPlan} from "../../store/actions/actionPlan";
import {filterDropdownGreenStepStyles, categoryDropdownGreenStepStyles} from "../../helpers/dropdown";

import BigCategories from "./bigCategory";
import SmallCategories from "./smallCategory";
import Notes from "./notes";
import CompletedByCategory from "./components/completedByCategory";

import './index.scss';
import {verifyOnboarding} from "../../store/actions/registration";
import InitialGreenSteps from "./components/initialGreenSteps";
import {getMunicipalities} from "../../store/actions/municipality";
import {getCompanyCarbonEmissions, getCompanyCarbonEmissions3rdparty} from "../../store/actions/carbon";
import { t } from 'i18next';

const getPercentByCategory = (mainCategory) => {
  let count = 0;
  let approvedCount = 0;
  let irrelevantCount = 0;

  let sumScore = (data) => {
    data.new_green_steps.forEach(greenStep => {
      count += greenStep.score;
      if(greenStep.new_company_green_steps.length>0) {
        if(greenStep.new_company_green_steps[0].irrelevant === true) {
          irrelevantCount += greenStep.score
        } else {
          approvedCount += greenStep.slider === true ? greenStep.new_company_green_steps[0].slider_score : greenStep.score
        }
      }
    });
  };

  mainCategory.forEach(item => {
    sumScore(item);
    item.categories.length>0 && item.categories.forEach(category => {
      sumScore(category);
    });
  });

  return Math.round((approvedCount*100)/(count-irrelevantCount));
};

const leafColors = [
  {id: 1, color: "#fff", secondColor: "#A7EEBF"},
  {id: 2, color: "#fff", secondColor: "#FFCEAC"},
  {id: 3, color: "#fff", secondColor: "#E3C9FC"}
];

const { Option } = components;

const IconOption = props => (
  <Option {...props}>
    {props.isSelected && <Check />}
    {props.data.label}
  </Option>
);

const tourSteps = [
  {
    title: t('onboarding_page.get_ideas'),
    content: (
      <div>
        {t('onboarding_page.green_steps')} <br />
        <b>{t('onboarding_page.green_steps2')}</b> {t('onboarding_page.green_steps3')} <b>{t('onboarding_page.green_steps4')}</b> {t('onboarding_page.green_steps5')}
        <br /><br />
        {t('onboarding_page.rating1')} <br />
        {t('onboarding_page.rating2')} <br />
        <b>{t('onboarding_page.rating3')}</b>. {t('onboarding_page.rating4')} <br />
        {t('onboarding_page.rating5')} <br />
      </div>
    ),
    locale: {next: t('onboarding_page.next')},
    styles: {
      options: {width: 500},
    },
    placement: 'center',
    target: '.greenStepPage',
  },
  {
    title: t('onboarding_page.create_plan'),
    content: (
      <div>
        {t('onboarding_page.create_plan2')} <br />
        {t('onboarding_page.create_plan3')}
        <br /><br />
        {t('onboarding_page.create_plan4')} <br />
        {t('onboarding_page.create_plan5')} <br />
        {t('onboarding_page.create_plan6')} <br />
      </div>
    ),
    locale: {next: t('onboarding_page.next') },
    styles: {
      options: {width: 500},
    },
    placement: 'center',
    target: '.greenStepPage',
  },
  {
    content: <div>{t('onboarding_page.press_heart')}</div>,
    locale: {last: t('onboarding_page.finish')},
    styles: {
      options: {width: 300},
    },
    placement: 'bottom',
    target: '.favorite',
  }
];

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const {state} = useLocation();
  const {loading, greenSteps, categoryIndex, loadingTips, tips} = useSelector( state => state.greenStep );
  const {loadingCompanyEmissions, loadingEmissions3rdparty} = useSelector( state => state.carbon );

  const storageCategory = localStorage.getItem('gp') ? parseInt(localStorage.getItem("gp"), 10) : 0;

  const [activeCategory, setActiveCategory] = useState(state && state.showCategory ? state.categoryIndex : storageCategory > 0 ? storageCategory : 0);
  const [activeBigCategory, setActiveBigCategory] = useState(0);
  const [activeSmallCategory, setActiveSmallCategory] = useState({labelText:'all_small_steps', label: 'Öll smá skref', value: 'all'});
  const [activeFilter, setActiveFilter] = useState( {labelText:'uncompleted_steps', label: 'uncompleted_steps', value: 'not_finished'});
  const [activeNotes, setActiveNotes] = useState( false);

  const [bigCategories, setBigCategories] = useState([]);
  const [bigCategoryData, setBigCategoryData] = useState(null);

  const [smallCategories, setSmallCategories] = useState([]);
  const [smallCategoryData, setSmallCategoryData] = useState(null);
  const [smallCategoriesName, setSmallCategoriesName] = useState(null);

  const [tourRun, setTourRun] = useState(false);
  const [alert, setAlert] = useState('');
  const [alert2, setAlert2] = useState('');

  const getData = (category, firstLoad = true) => {
    let bigData = [];
    let smallData = [];
    let smallCategoriesNameData = [];

    greenSteps && greenSteps.results && greenSteps.results[category].children_category.forEach(item => {
      if(item.icon !== null) {
        bigData.push({
          ...item,
          "new_green_steps_not_completed": item.new_green_steps.filter(f =>
            f.id === 205 || f.id === 206 || f.id === 154 || f.id === 178 || f.id === 218  ||
            f.new_company_green_steps.length === 0 ||
            (firstLoad && f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].hasOwnProperty('addedRecent')) ||
            (f.slider === true && f.new_company_green_steps.length > 0 && f.score > f.new_company_green_steps[0].slider_score)
          ),
          "new_green_steps_completed": item.new_green_steps.filter(f =>
            (f.id !== 205 && f.id !== 206 && f.id !== 154 && f.id !== 178 && f.id !== 218) &&
            (f.slider === true ? f.new_company_green_steps.length > 0 && f.score === f.new_company_green_steps[0].slider_score : f.new_company_green_steps.length > 0) &&
            (
              (f.new_company_green_steps[0].irrelevant === false || f.new_company_green_steps[0].irrelevant === null) ||
              !f.new_company_green_steps[0].hasOwnProperty('irrelevant')
            )
          ),
          "new_green_steps_completed_irrelevant": item.new_green_steps.filter(f => f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].irrelevant === true),
          "categories": item.categories,
        })
      } else {
        let tempSmall = [];
        if(item.categories.length>0) {
          item.categories.forEach(c => tempSmall.push(...c.new_green_steps))
          smallCategoriesNameData.push(...item.categories)
        } else if(item.new_green_steps.length>0) {
          tempSmall.push(...item.new_green_steps)
          smallCategoriesNameData.push(item)
        }

        tempSmall.length>0 && smallData.push({
          ...item,
          "all_categories": item.categories.length>0 ? item.categories : item,
          "new_green_steps_not_completed": tempSmall.filter(f => f.new_company_green_steps.length === 0 || (firstLoad && f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].hasOwnProperty('addedRecent') && !f.new_company_green_steps[0].hasOwnProperty('comment') && !f.new_company_green_steps[0].hasOwnProperty('reason'))),
          "new_green_steps_completed": tempSmall.filter(f => f.new_company_green_steps.length > 0 && ((f.new_company_green_steps[0].irrelevant === false || f.new_company_green_steps[0].irrelevant === null) || !f.new_company_green_steps[0].hasOwnProperty('irrelevant'))),
          "new_green_steps_completed_irrelevant": tempSmall.filter(f => f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].irrelevant === true),
        })
      }
    });

    setBigCategories(bigData);
    if(storageCategory === category && localStorage.getItem('gp2')){
      let storageBigCategory = parseInt(localStorage.getItem("gp2"), 10);
      setActiveBigCategory(storageBigCategory);
      setBigCategoryData([bigData[storageBigCategory]]);
    } else {
      setBigCategoryData(bigData);
    }

    setSmallCategories(smallData);
    if(activeCategory === 0 && activeBigCategory === 0) {
      setSmallCategoryData([])
    } else {
      setSmallCategoryData(smallData);
    }
    setSmallCategoriesName(smallCategoriesNameData);
  };

  const handleShowTour = () => {
    setAlert2('');
    setTourRun(true);
    dispatch(verifyOnboarding(companyId));
  };

  const handleSkipTour = () => {
    setAlert2('');
    dispatch(verifyOnboarding(companyId));
  };

  const handleShowFirstTour = () => {
    setAlert('');
    setAlert2(
      <Modal isOpen={true} centered backdrop={"static"} className="tourFistStep" style={{maxWidth: '500px', width: '100%'}}>
        <div className="modal-body">
          <div className="title"> {t('onboarding_page.welcome')} {userData.user.name} </div>
          <div className="copy">
            {t('onboarding_page.welcome1')} <br/>
            {t('onboarding_page.welcome2')}<br/><br/>
            {t('onboarding_page.welcome3')}
          </div>
          <div className="btns">
            <div className="btn skip" onClick={() => handleSkipTour()}>{t('onboarding_page.check_out')}</div>
            <div className="btn go" onClick={() => handleShowTour()}>{t('onboarding_page.start_navigation')}</div>
          </div>
          <div className="copyBottom">
            {t('onboarding_page.welcome4')}
          </div>
        </div>
      </Modal>
    );
  };

  const toggleCategory = (tab) => {
    if (activeCategory !== tab) {
      getData(tab, false);

      setActiveNotes(false);
      setActiveCategory(tab);
      setActiveBigCategory(0);
      setActiveSmallCategory({labelText:'all_small_steps', label: 'Öll smá skref', value: 'all'});
      setActiveFilter({labelText:'uncompleted_steps', label: 'uncompleted_steps', value: 'not_finished'});

      localStorage.setItem('gp', tab);
      localStorage.removeItem('gp2');
    }
  };

  useEffect(() => {
    dispatch(getGreenStepsNew(companyId));
    dispatch(getGreenStepTips());
    dispatch(getCompanyCarbonEmissions(companyId));
    dispatch(getCompanyCarbonEmissions3rdparty(companyId));
    firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null && dispatch(getMunicipalities());
    firstCompany && firstCompany.onboarded === false && setAlert(<InitialGreenSteps handleClose={() => handleShowFirstTour()} />)
  }, []);

  useEffect(() => {
    if(categoryIndex>0) setActiveCategory(categoryIndex);
  }, [categoryIndex]);

  useEffect(() => {
    if(greenSteps && greenSteps.results) {
      getData(activeCategory);
    }
  }, [greenSteps])

  useEffect(() => {
    if(greenSteps && state && state.addOwnCarbon && state.mainCategory>0) {
      toggleCategory(state.mainCategory);
    }
  },[state, greenSteps]);

  useEffect(() => {
    if(greenSteps && state && state.showTour) {
      handleShowFirstTour();
    }
  },[state, greenSteps]);

  if (loading || !greenSteps || !greenSteps.results || loadingTips || loadingCompanyEmissions || loadingEmissions3rdparty) return (<LoaderSpinner/>);

  const selectBigCategory = (category) => {
    setBigCategoryData(category === 'all' ? bigCategories : [bigCategories[category]]);
    setActiveBigCategory(category);
    setActiveNotes(false);

    if(category === 'all') {
      localStorage.removeItem('gp2');
    } else {
      localStorage.setItem('gp2', category);
    }


    if(category === 'all') {
      setActiveSmallCategory({labelText:'all_small_steps', label: 'Öll smá skref', value: 'all'})
      setSmallCategoryData(smallCategories);
    } else {
      let findInSmallCategory = smallCategories.findIndex(f => f.name === bigCategories[category].name);
      if(findInSmallCategory>=0) {
        let getSmallCategory  = smallCategories[findInSmallCategory];
        selectSmallCategory({
          catEn:getSmallCategory.name_en,
          catIs:getSmallCategory.name,
          label: getSmallCategory["name_" + currentLang] ? getSmallCategory["name_" + currentLang] : getSmallCategory.name,
          value: findInSmallCategory
        })
      } else {
        if(activeCategory === 0) {
          setActiveSmallCategory({labelText:'all_small_steps', label: 'Öll smá skref', value: 'all'})
          setSmallCategoryData([])
        } else {
          setActiveSmallCategory({labelText:'all_small_steps', label: 'Öll smá skref', value: 'all'})
        }

      }
    }

  };

  const selectSmallCategory = (category) => {
    setSmallCategoryData(category.value === 'all' ? smallCategories : [smallCategories[category.value]]);
    setActiveSmallCategory(category);
  };

  const selectNotes = () => {
    setActiveNotes(true);
  };

  const handleCheck = (data) => {
    dispatch(createCompanyGreenStep(data, companyId, activeCategory));
  };

  const handleUpdate = (data) => {
    dispatch(updateCompanyGreenStep(data, companyId, activeCategory));
  };

  const handleUnCheck = (id) => {
    dispatch(deleteCompanyGreenStep(id, activeCategory, 'deleteCompletely'));
  };

  const addActionPlan = (title, id, startDate, endDate) => {
    let newData = {
      "company_id": companyId,
      "action": title,
      "type": 'green_step',
      "priority": null,
      "start_date": startDate,
      "end_date": endDate,
      "plan_goal_id": null,
      "subgoal": "",
      "status": null,
      "user_ids": null,
      "comment": null,
      "new_green_step_id": id
    };
    dispatch(createUpdateActionPlan(false, newData, activeCategory));
  };

  const handelDeleteActionPlan = (id) => {
    dispatch(deleteActionPlan(id, companyId, activeCategory))
  };

  const handleAddComment = (id, comment, type) => {
    dispatch(editComment(id, comment, activeCategory, type));
  };

  return (
    <div className="page-content greenStepPage" data-category={activeCategory}>
      {alert2}
      {alert}

      <Joyride
        continuous
        hideCloseButton
        hideSkipButton
        hideBackButton
        run={tourRun}
        showProgress
        steps={tourSteps}
        styles={{options: {zIndex: 10000}}}
      />

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <Nav tabs className="nav-tabs-custom nav-justified">
            {greenSteps && greenSteps.results && greenSteps.results.map((greenStep, greenStepIndex) => {
              let percent = getPercentByCategory(greenStep.children_category);
              return (
                <NavItem category={greenStep.id} key={'nav_category_'+greenStepIndex} name={greenStep.name}>
                  <NavLink
                    className={`${activeCategory === greenStepIndex ? 'active' : ''}`}
                    onClick={() => {toggleCategory(greenStepIndex)}}
                  >
                    <LeafIcon
                      key={"leaf_item"+greenStepIndex}
                      id={"nav_tooltip_leaf_item"+greenStepIndex}
                      className="leafIcon"
                      colors={leafColors.find(color => color.id === greenStep.id)}
                      percentage={percent > 0 ? percent/100 : 0}
                    />

                    <span className="leafName">
                      {greenStep["name_"+currentLang] ? greenStep["name_"+currentLang] : greenStep.name}
                    </span>

                    <UncontrolledTooltip placement="top" target={'nav_tooltip_leaf_item'+greenStepIndex}>
                      {percent}%
                    </UncontrolledTooltip>
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
        </div>
      </div>

      <div className="row filterRow">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <div className="categories">
            <span
              onClick={() => selectBigCategory('all')}
              className={`${activeBigCategory === 'all' && 'categoryTextColor active'}`}
            >
               {t('pluses_page.all_steps')}
            </span>
            {bigCategories && bigCategories.map((category, catIndex) =>
              <span
                onClick={() => selectBigCategory(catIndex)}
                className={`${activeBigCategory === catIndex && activeNotes === false && 'categoryTextColor active'}`}
                key={activeCategory+"_bigCategory_"+catIndex}
              >
                {category["name_" + currentLang] ? category["name_" + currentLang] : category.name}
              </span>
            )}
            {(activeCategory === 0 || activeCategory === 1) && activeFilter.value === 'not_finished' &&
              <span onClick={() => selectNotes()} className={`${activeNotes && 'categoryTextColor active'}`}>
                {t('dashboard_page.tips')}
              </span>
            }
          </div>
          <div className="filters">
            <div className="customCheckIcon"> <Check/> </div>
            <Select
              components={{ Option: IconOption }}
              placeholder={t('select')}
              value={{label: t(`pluses_page.${activeFilter.labelText}`), value: activeFilter.value}}
              onChange={(event) => setActiveFilter(event)}
              styles={filterDropdownGreenStepStyles}
              // menuIsOpen={true}
              options={[
                {labelText:'uncompleted_steps', label: t('pluses_page.uncompleted_steps'), value: 'not_finished'},
                {labelText:'completed_steps', label: t('pluses_page.completed_steps'), value: 'finished'}
              ]}
            />
           </div>
        </div>
      </div>

      {activeFilter.value === 'not_finished' && activeNotes === false &&
        <div className="row bigGreenStepsRow">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <BigCategories
              mainCategoryIndex={activeCategory}
              bigCategory={bigCategoryData}
              check={(data) => handleCheck(data)}
              unCheck={(id) => handleUnCheck(id)}
              update={(data) => handleUpdate(data)}
              addComment={(id, comment, type) => handleAddComment(id, comment, type)}
              addActionPlan={(title, id, startDate, endDate) => addActionPlan(title, id, startDate, endDate)}
              deleteActionPlan={(id) => handelDeleteActionPlan(id)}
            />
          </div>
        </div>
      }


      {activeFilter.value === 'finished' && activeNotes === false  &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <CompletedByCategory
              data={bigCategoryData}
              mainCategoryIndex={activeCategory}
              type="big"
            />
          </div>
        </div>
      }

      {activeCategory >= 0 && activeCategory < 2 && activeNotes === false &&
      smallCategoryData &&
      ((activeFilter.value === 'finished' && smallCategoryData.filter(f => f.new_green_steps_completed.length > 0).length > 0)
        || (activeFilter.value === 'not_finished' && smallCategoryData.filter(f => f.new_green_steps_not_completed.length > 0).length > 0))
      &&
        <div className="row filterSmallGreenStepsRow">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <div className="filters">
              <div className="customCheckIcon"><Check/></div>
              <Select
                components={{Option: IconOption}}
                placeholder={t('select')}
                // value={activeSmallCategory}
                value={{
                  label: activeSmallCategory.value === 'all' ? t(`pluses_page.${activeSmallCategory.labelText}`) :
                    currentLang === 'en' ? activeSmallCategory.catEn : activeSmallCategory.catIs,
                  value: activeSmallCategory.value
                }}
                onChange={(event) => selectSmallCategory(event)}
                styles={categoryDropdownGreenStepStyles}
                options={[
                  {labelText: 'all_small_steps', label: t('pluses_page.all_small_steps'), value: 'all'},
                  ...smallCategories && smallCategories.map((category, catIndex) => {
                    return {
                      catEn: category.name_en,
                      catIs: category.name,
                      label: category["name_" + currentLang] ? category["name_" + currentLang] : category.name,
                      value: catIndex
                    }
                  })
                ]}
              />
            </div>
          </div>
        </div>
      }


      {activeFilter.value === 'not_finished' && activeNotes === false &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <SmallCategories
              mainCategoryIndex={activeCategory}
              notCompleted={smallCategoryData}
              smallCategoriesName={smallCategoriesName}
              check={(data) => handleCheck(data)}
              unCheck={(id) => handleUnCheck(id)}
              addComment={(id, comment, type) => handleAddComment(id, comment, type)}
              addActionPlan={(title, id, startDate, endDate) => addActionPlan(title, id, startDate, endDate)}
              deleteActionPlan={(id) => handelDeleteActionPlan(id)}
            />
          </div>
        </div>
      }

      {activeFilter.value === 'finished' && activeNotes === false && activeCategory < 2 &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <CompletedByCategory
              data={smallCategoryData}
              mainCategoryIndex={activeCategory}
              type="small"
            />
          </div>
        </div>
      }

      {activeNotes === true &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <Notes
              tips={tips}
              mainCategoryIndex={activeCategory+1}
            />
          </div>
        </div>
      }


    </div>
  )
};

export default Index