import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Check, Close, Edit} from "@mui/icons-material";
import {createCompanyGreenStep, deleteCompanyGreenStep, updateCompanyGreenStep} from "../../../store/actions/greenStep";
import FileUploadPdf2 from "../../../components/Common/CustomUpload/FileUploadPdf2";
import {Input, Label} from "reactstrap";

import pdfIcon from "../../../assets/images/pdf1.png";
import pdfIcon3 from "../../../assets/images/pdf3.png";
import pdfIcon2 from "../../../assets/images/pdf2.png";
import i18n from "../../../i18n";
import {useNavigate} from "react-router-dom";

const RenderPdfFileContainer = (props) => {
  const {mainCategoryIndex, categoryItem, requiredFrom, comment} = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const isInstitution = firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null;

  const [editPdfId, setEditPdfId] = useState(0);
  const [pdfLink, setPdfLink] = useState(categoryItem && categoryItem.new_company_green_steps.length>0 ? categoryItem.new_company_green_steps[0].link : '');

  const uploadAndSaveGreenStep = (item, file, fileName, link = '') => {
    if(file.length > 0 || link.length > 0) {
      let companyGreenStep = item.new_company_green_steps.length>0 ? item.new_company_green_steps[0] : false;
      let companyComment = companyGreenStep && companyGreenStep.comment && companyGreenStep.comment.length>0 ? JSON.parse(companyGreenStep.comment) : [];

      if(requiredFrom) {
        if(props.formValid) {
          companyComment = comment;
        } else {
          props.changeErrorStatus(true);
          return false;
        }
      }

      let data = {
        "id": companyGreenStep ? companyGreenStep.id : 0,
        "new_green_step_id": item.id,
        "comment": companyComment,
        "irrelevant": false,
        "link": link,
        "reason": companyGreenStep && companyGreenStep.reason && companyGreenStep.reason.length>0 ? JSON.parse(companyGreenStep.reason) : [],
        "pdf": file.includes('https') ? null : file,
        "pdf_name": fileName
      };

      if(data.id > 0) {
        dispatch(updateCompanyGreenStep(data, companyId, mainCategoryIndex, props.dashboard));
      } else {
        dispatch(createCompanyGreenStep(data, companyId, mainCategoryIndex));
      }

      setEditPdfId(0);

      if(requiredFrom) {
        props.changeErrorStatus(false);
      }
    } else {
      return false;
    }
  };

  const deleteGreenStepItem = (data) => {
    dispatch(deleteCompanyGreenStep(data.new_company_green_steps[0].id, mainCategoryIndex, 'delete', false, props.dashboard));
  };

  if(!categoryItem) {
    return ""
  }

  const openGreenStep = (id, mainCategory) => {
    navigate('/adgerdir', { state: {"addOwnCarbon": id, "mainCategory": mainCategory}});
  };

  return (
   <React.Fragment>

     {props.dashboard && <div className="title categoryTextColor">
       {categoryItem["policy_name_"+currentLang] ? categoryItem["policy_name_"+currentLang] : categoryItem.policy_name}
     </div> }

      {categoryItem.new_company_green_steps.length>0 &&
      (categoryItem.new_company_green_steps[0].pdf !== "" || categoryItem.new_company_green_steps[0].link !== "") &&
      editPdfId !== categoryItem.id &&
        <div className="pdfContainer">
          {categoryItem.new_company_green_steps[0].link ?
            <div className="pdfLink categoryTextColor">
            { t('pluses_page.link_to_data') }: <a href={categoryItem.new_company_green_steps[0].link} className="categoryTextColor" target="_blank" rel="noopener noreferrer">{categoryItem.new_company_green_steps[0].link}</a>
            </div>
            :
            <a href={categoryItem.new_company_green_steps[0].pdf} target="_blank" rel="noopener noreferrer">
              <img className='pdf_icon' src={
                props && props.mainCategoryIndex ?
                  (props.mainCategoryIndex === 2 ? pdfIcon3 : props.mainCategoryIndex === 1 ? pdfIcon2 : pdfIcon)
                  : pdfIcon
              } alt="PDF file"/>
              <span className="pdf_name categoryTextColor"> {categoryItem.new_company_green_steps[0].pdf_name}</span>
            </a>
          }

          {!isInstitution &&
            <p
              className="companyCompletedIcon categoryBorderColor true edit hover-effect"
              onClick={() => {setEditPdfId(categoryItem.id)}}
            >
              <Edit/>
            </p>
          }
          {!isInstitution &&
            <p className="companyCompletedIcon categoryBorderColor true hover-effect"
               onClick={() => {deleteGreenStepItem(categoryItem)}}
            >
              <Close/>
            </p>
          }
        </div>
      }

      {((categoryItem.new_company_green_steps.length === 0 && !props.dashboard) || editPdfId === categoryItem.id) &&
        <React.Fragment>
          <FileUploadPdf2
            fileUploadKey={"renderBigCategory_renderPdfFileContainer_" + categoryItem.id}
            onUploadPDF={(file, fileName) => uploadAndSaveGreenStep(categoryItem, file, fileName)}
            msg={t('pluses_page.pdf')}
            mainCategoryIndex={mainCategoryIndex}
            disabled={Object.hasOwn(props, 'formValid') ? !props.formValid : false}
          />

          <div className="pdfContainerfromLink">
            <Label className="form_label categoryTextColor">{ t('pluses_page.link_to_data') }</Label>
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                <Input
                  name="action_input"
                  type="text"
                  className="form-control"
                  value={pdfLink}
                  onChange={(e) => {setPdfLink(e.target.value)}}
                />
              </div>
              <div className="col-2">
                <div className="companyCompleted">
                  <div className={`companyCompletedIcon categoryBorderColor checkbox true`} onClick={() => uploadAndSaveGreenStep(categoryItem, '', '', pdfLink)}>
                    <Check/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      }

     {(categoryItem.new_company_green_steps.length === 0 && props.dashboard && editPdfId !== categoryItem.id) &&
      <div className="openGreenStep categoryTextColor" onClick={() => openGreenStep(categoryItem.id, categoryItem.main_category-1)}>
        {t('dashboard_page.go_to_action')}
      </div>
     }

   </React.Fragment>
  )
};

export default RenderPdfFileContainer